import React from "react"
import { useListContext, ShowButton } from "react-admin"
import { BASE_URL } from "../../providers/dataProvider"

const DetailButton = ({ record = {}, resourceName = '' }) => {
    const route = `${resourceName}/${record.id}/show`

    const {
        page
    } = useListContext()

    return (
        <ShowButton
            label="Detalle"
            onClick={() => localStorage.setItem(`pagination/${resourceName}`, String(page))}
            basePath={BASE_URL}
            resource="zicsa/integration_result"
            to={route}
        />
    )
}

export default DetailButton