import React, { useState } from 'react'
import { useAuthProvider } from 'react-admin'
import { Redirect } from 'react-router-dom'
import { CircularProgress, Container, CssBaseline, Avatar, Box, TextField, Button, Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { green } from '@material-ui/core/colors'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import {
  Alert, AlertTitle
} from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://hitch.cl/" target="_blank">
        Middleware
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Login = (props) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const classes = useStyles()
  const { login } = useAuthProvider()

  const submit = (e) => {
    e.preventDefault()
    setLoading(true)
    login({ username, password })
    .then(() => setRedirect('/'))
    .catch((err) => {
      setLoading(false)
      setError(err)
    })
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div style={{
        marginTop: 64,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Avatar style={{
          margin: 12,
          backgroundColor: '#1976d2'
        }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Middleware
        </Typography>
        <form
          onSubmit={(e) => submit(e)}
          style={{
            width: '100%',
            marginTop: 12,
            position: 'relative'
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoFocus
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            style={{ margin: '10 10 10' }}
          >
            Ingresar
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </form>
        {
          error && <Alert severity="error" style={{ marginTop: '1em', width: '100%' }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        }
        { redirect && <Redirect to={redirect} /> }
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Login;
