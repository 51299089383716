import React, { useState, cloneElement } from "react"
import { Redirect } from 'react-router-dom'
import { Chip, Button } from '@material-ui/core'
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  TextInput,
  SelectInput,
  Filter,
  Pagination,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  usePermissions,
  useRedirect
} from 'react-admin'
import { BASE_URL } from "../../../providers/dataProvider"
import AddIcon from '@material-ui/icons/Add'

const statusMessage = (status) => {
  if (status) {
    return 'Habilitado'
  }
  else {
    return 'No Habilitado'
  }
}

const statusColor = (status) => {
  if (status) {
    return 'green'
  }
  else {
    return 'grey'
  }
}

const statusChoices = [
  { id: 'true', name: 'Habilitado' },
  { id: 'false', name: 'No Habilitado' }
]

const FullNameField = ({ record = {} }) => (<span>{record.firstname} {record.lastname}</span>)
const StatusField = ({ record = {}}) => (<Chip label={statusMessage(record.active)} style={{ color: 'white', backgroundColor: statusColor(record.active) }} />)
const CustomerFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <SelectInput source="active" label="Estado" choices={statusChoices} />
  </Filter>
)
const AdminField = ({ record = {} }) => (<span>{ record.isadmin ? 'SI' : 'NO' }</span>)

const CustomerPagination = (props) => (<Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />)

const DetailButton = ({ record = {} }) => {
  const route = `staff/${record.id}/show`

  return (
    <ShowButton
      label="Detalle"
      basePath={BASE_URL}
      resource="auth/staff"
      to={route}
  />
  ) 
}

const canWrite = (permissions) => {
  if (!permissions) {
    return false
  }

  if (!permissions['admin']) {
    return false
  }

  return permissions['admin/staff']['write']
}

const StaffActions = (props) => {
  const { permissions } = usePermissions()
  const [route, setRoute] = useState(null)
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
        {
          canWrite(permissions) && (
            <Button
              onClick={() => { setRoute(`/admin/staff/create`) }}
              startIcon={<AddIcon />}
              color="primary"
              style={{ fontSize: '0.8125rem', padding: '4px 5px' }}
            >
              Nuevo
            </Button>
          )
        }
        {
          route
          ? <Redirect to={route} />
          : null
        }
    </TopToolbar>
  )
}

const checkPermissions = (permissions, redirect) => {
  if (!permissions) {
    return
  }

  if ((permissions['admin'] || false) === false) {
    redirect('/forbidden')
  }
  if ((permissions['admin/staff'] || false) === false) {
    redirect('/forbidden')
  }
}

export default (props) => {
  const { permissions } = usePermissions()
  const redirect = useRedirect()

  checkPermissions(permissions, redirect)
  
  return (
    <List
      perPage={25}
      title="Usuarios Middleware"
      filters={<CustomerFilter />}
      bulkActionButtons={false}
      exporter={false}
      pagination={<CustomerPagination />}
      basePath={BASE_URL}
      resource="auth/users"
      actions={<StaffActions />}
      {...props}
    >
      <Datagrid stickyHeader >
        <FullNameField label="Nombre" emptyText="--" sortable />
        <TextField source="username" label="Usuario" sortable />
        <TextField source="identification" label="Rut" sortable />
        <TextField source="email" label="Email" sortable />
        <AdminField label="Admin" sortable />
        <StatusField label="Estado" sortable />
        <DetailButton />
      </Datagrid>
    </List>
  )
}
