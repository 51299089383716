import React from "react"
import { Route } from 'react-router-dom'

import StaffList from './components/admin/Staff/StaffList'
import StaffShow from './components/admin/Staff/StaffShow'
import StaffEdit from "./components/admin/Staff/StaffEdit"
import PermissionsEdit from "./components/admin/Staff/PermissionsEdit"
import StaffCreate from "./components/admin/Staff/StaffCreate"
import Forbidden from "./components/Forbidden"
import Me from "./components/Me"
import ChangePassword from "./components/ChangePassword"

import ZicsaPurchaseOrder from "./components/zicsa/PurchaseOrder"
import ZicsaInvoice from "./components/zicsa/Invoice"
import ZicsaStatusInvoice from "./components/zicsa/StatusInvoice"
import ZicsaJournal from "./components/zicsa/Journal"

const routes = [
    <Route exact path="/zicsa/purchase_orders" component={ZicsaPurchaseOrder.IntegrationResultList} />,
    <Route exact path="/zicsa/purchase_orders/:id/show" component={ZicsaPurchaseOrder.IntegrationResultShow} />,
    <Route exact path="/zicsa/invoices" component={ZicsaInvoice.IntegrationResultList} />,
    <Route exact path="/zicsa/invoices/:id/show" component={ZicsaInvoice.IntegrationResultShow} />,
    <Route exact path="/zicsa/status_invoices" component={ZicsaStatusInvoice.IntegrationResultList} />,
    <Route exact path="/zicsa/status_invoices/:id/show" component={ZicsaStatusInvoice.IntegrationResultShow} />,
    <Route exact path="/zicsa/invoices_journal" component={ZicsaJournal.IntegrationResultList} />,
    <Route exact path="/zicsa/invoices_journal/:id/show" component={ZicsaJournal.IntegrationResultShow} />,
    
    <Route exact path="/admin/staff" component={StaffList} />,
    <Route exact path="/admin/staff/:id/show" component={StaffShow} />,
    <Route exact path="/admin/staff/:id/edit" component={StaffEdit} />,
    <Route exact path="/admin/staff/:id/permissions/edit" component={PermissionsEdit} />,
    <Route exact path="/admin/staff/create" component={StaffCreate} />,
    <Route exact path="/forbidden" component={Forbidden} />,
    <Route exact path="/me" component={Me} />,
    <Route exact path="/me/change_password" component={ChangePassword} />,
]

export default routes
