import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'

export const statusMessage = (status) => {
  if (status === 'InProgress' || !status) {
    return 'En Progreso'
  }
  else if (status === 'Pendient') {
    return 'Pendiente'
  }
  else if (status === 'InReview') {
    return 'En Revision'
  }
  else if (status === 'Finished') {
    return 'Integrado'
  }
  else if (status === 'Failed') {
    return 'Fallido'
  }
  else if (status === 'InReview') {
    return 'En Revision'
  }
  else {
    return 'Cerrado'
  }
}

export const statusColor = (status) =>  {
  if (status === 'Closed') {
    return 'grey'
  }
  else if (status === 'Finished') {
    return 'green'
  }
  else if (status === 'Failed') {
    return 'red'
  }
  else if (status === 'InReview') {
    return 'orange'
  }
  else {
    return 'yellow'
  }
}

export const statusClass = (status) => {
  if (status === 'InProgress' || !status) {
    return 'pendient'
  }
  else if (status === 'Finished') {
    return 'finished'
  }
  else if (status === 'Failed') {
    return 'failed'
  }
  else {
    return 'pendient'
  }
}

export const StatusField = ({ record = {} }) => (
  <Typography variant="body1" component="p">
    <b>Estado:</b> <Chip size="small" label={statusMessage(record.status)} style={{ color: statusColor(record.status) === 'yellow' ? 'black' : 'white', backgroundColor: statusColor(record.status) }} />
  </Typography>
)

export const StatusFieldCell = ({ record = {}}) => (
  <Grid container direction="row" wrap="nowrap" alignItems="center">
    <Chip label={statusMessage(record.status)} style={{ color: statusColor(record.status) === 'yellow' ? 'black' : 'white', backgroundColor: statusColor(record.status) }} />
    {
      record.status == 'Failed' && (
        <Tooltip title={record.error}>
          <ErrorIcon color="error" />
        </Tooltip>
      )
    }
  </Grid>
)