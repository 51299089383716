import React, { useState } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress
} from '@material-ui/core'
import { useEffect } from 'react'

const appLabel = (app) => {
  if (app === 'zicsa') {
    return 'Zicsa'
  }
  else if (app === 'admin') {
    return 'Administracion'
  }
}

const menuLabel = (menu) => {
  if (menu === 'staff') {
    return 'Usuarios'
  }
  else if (menu === 'purchase_orders') {
    return 'Ordenes de compra'
  }
  else if (menu === 'invoices') {
    return 'Facturas'
  }
  else if (menu === 'status_invoices') {
    return 'Estado facturas'
  }
  else if (menu === 'invoices_journal') {
    return 'Registros contables'
  }
}

const PermissionRow = ({ record, isAdmin }) => {
  return (
    <TableRow>
      <TableCell
        align="left"
      >
        {appLabel(record.app)}
      </TableCell>
      <TableCell
        align="left"
      >
        {menuLabel(record.menu)}
      </TableCell>
      <TableCell
        align="left"
      >
        { 
          record.read || isAdmin
          ? 'SI'
          : 'NO'
        }
      </TableCell>
      <TableCell
        align="left"
      >
        { 
          record.write || isAdmin
          ? 'SI'
          : 'NO'
        }
      </TableCell>
    </TableRow>
  )
}

export default ({ data, loaded, basePath, isAdmin = false }) => {
  const [permissions, setPermissions] = useState(null)

  useEffect(() => {
    if (data) {
      const items = Object.values(data)
        .reduce((accu, permission) => {
          accu[`${permission.app}-${permission.menu}`] = permission
          return accu
        }, {})
      console.log(items)
      setPermissions(items)
    }
  }, [data])

  if ((loaded && data) == false) {
    return <LinearProgress />
  }

  if (!permissions) {
    return <LinearProgress />
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            align="left"
          >
            Aplicación
          </TableCell>
          <TableCell
            align="left"
          >
            Menu
          </TableCell>
          <TableCell
            align="left"
          >
            Lectura
          </TableCell>
          <TableCell
            align="left"
          >
            Escritura
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          Object.keys(permissions || {}).length > 0 && (
            <React.Fragment>
              <PermissionRow isAdmin={isAdmin} record={permissions['zicsa-purchase_orders']} />
              <PermissionRow isAdmin={isAdmin} record={permissions['zicsa-invoices']} />
              <PermissionRow isAdmin={isAdmin} record={permissions['zicsa-status_invoices']} />
              <PermissionRow isAdmin={isAdmin} record={permissions['zicsa-invoices_journal']} />

              <PermissionRow isAdmin={isAdmin} record={permissions['admin-staff']} />
            </React.Fragment>
          )
        }
      </TableBody>
    </Table>
  )
}
