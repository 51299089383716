import React from 'react'
import { useGetIdentity, MenuItemLink, UserMenu } from 'react-admin'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'

const UserMenuComponent = (props) => {
  const { identity } = useGetIdentity()

  return (
    <UserMenu label={identity ? identity.fullName : ''} {...props}>
      <MenuItemLink
        to="/me"
        primaryText="Mi Cuenta"
        leftIcon={<VerifiedUserIcon />}
      />
    </UserMenu>
  )
}

export default UserMenuComponent