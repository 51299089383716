import axios from 'axios'
import { BASE_URL } from './dataProvider'

export default {
  login: async ({ username, password }) => {
    try {
      const response = await axios.post(`${BASE_URL}/auth/login`, {
        username, password
      })
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('user', JSON.stringify(response.data.user))
      localStorage.setItem('permissions', JSON.stringify(response.data.permissions))
      return Promise.resolve()
    }
    catch (err) {
      const error = err.response
        ? err.response.data.message
        : 'Ha fallado la autenticación. Intentalo nuevamente'

      return Promise.reject(error)
    }
  },
  logout: () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('permissions')
    return Promise.resolve()
  },
  checkError: ({ status }) => {
    if (status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('permissions')
      return Promise.reject({ redirectTo: '/login', message: false })
    }
    else if (status === 403) {
      return Promise.reject({ redirectTo: '/forbidden', logoutUser: false, message: false })
    }
    return Promise.resolve()
  },
  checkAuth: async () => {
    try {
      const token = localStorage.getItem('token')
      const user = localStorage.getItem('user')
      const permissions = localStorage.getItem('permissions')

      if (!token || !user || !permissions) {
        return Promise.reject({ message: false })
      }
      
      return Promise.resolve()
    }
    catch (err) {
      return Promise.reject({ message: false })
    }
  },
  getPermissions: () => {
    try {
      const permissions = JSON.parse(localStorage.getItem('permissions'))
      return Promise.resolve(permissions)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  getIdentity: () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'))
      return Promise.resolve({
        id: user.id,
        fullName: `${user.firstname} ${user.lastname}`,
        user
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
