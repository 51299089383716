import React from 'react'
import {
  useNotify,
  useRefresh,
  useRedirect,
  useGetIdentity,
  PasswordInput
} from 'react-admin'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardContent,
  LinearProgress
} from '@material-ui/core'
import { changePassword } from '../providers/dataProvider'

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true
}

const ChangePassword = () => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const { identity } = useGetIdentity()

  const onSubmit = (values) => {
    notify('Cambiando password...', 'info', null, false, 3000)

    changePassword(identity.user.id, values.password1)
    .then(() => {
      notify('Password modificada', 'success', null, false, 3000)
      redirect(`/me`)
      refresh()        
    })
    .catch(err => {
      notify('No logro cambiarse la password', 'error', null, false, 3000)
    })
  }

  const validatePassword = (values) => {
    const errors = {}

    if ((values.password1 || '').length === 0) {
      errors.password1 = 'Debes ingresar la nueva password'
    }
    if ((values.password2 || '').length === 0) {
      errors.password2 = 'Debes repetir la nueva password'
    }

    if ((values.password1 || '').length < 6) {
      errors.password1 = 'La password debe tener 6 o mas caracteres'
    }

    if ((values.password1 || '').length >= 6 && (values.password2 || '').length > 0) {
      if (values.password1 !== values.password2) {
        errors.password2 = 'La password no coincide'
      }
    }

    return errors
  }

  return (
    identity ? (
      <Card>
        <CardContent>
          <Form
            initialValues={{}}
            validate={validatePassword}
            onSubmit={onSubmit}
            mutators={{ ...arrayMutators }}
            subscription={defaultSubscription}
            key={identity.user.id}
            keepDirtyOnReinitialize
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TableContainer style={{ marginTop: '1em' }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell><b>Nueva Password</b></TableCell>
                        <TableCell>
                          <PasswordInput source="password1" label="" fullWidth />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><b>Repetir Nueva Password</b></TableCell>
                        <TableCell>
                          <PasswordInput source="password2" label="" fullWidth />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Toolbar>
                  <Box style={{ marginTop: '3em' }} display="flex" justifyContent="flex-start" width="100%">
                    <Button variant="contained" type="submit" color="primary" startIcon={<SaveIcon />}>
                      SAVE
                    </Button>
                  </Box>
                </Toolbar>
              </form>
            )}
          />
        </CardContent>
      </Card>
    )
    : <LinearProgress />
  )
}

export default ChangePassword
