import React from "react"
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Title } from 'react-admin'

const Forbidden = () => (
    <Card>
        <Title title="Prohibido" />
        <CardContent>
            <h1>No tienes permitido el acceso</h1>
        </CardContent>
    </Card>
)

export default Forbidden
