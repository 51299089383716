import React from "react"
import { Admin, Resource } from 'react-admin'
import Login from './components/Login'
import Layout from './components/Layout'
import authProvider from './providers/authProvider'
import dataProvider from './providers/dataProvider'
import i18nProvider from './providers/i18nProvider'
import routes from './routes'
import { ConfirmProvider } from 'material-ui-confirm'
import Dashboard from './components/Dashboard'

const App = () => (
  <ConfirmProvider>
    <Admin
      title="Middleware"
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={Login}
      layout={Layout}
      customRoutes={routes}
      i18nProvider={i18nProvider}
      locale="en"
      dashboard={Dashboard}
    >
      <Resource name="zicsa/invoices" />
      <Resource name="zicsa/invoices_journal" />
      <Resource name="zicsa/purchase_orders" />
      <Resource name="zicsa/status_invoices" />
      <Resource name="zicsa/integration_result" />
      <Resource name="auth/users" />
      <Resource name="auth/users_permissions" />
    </Admin>
  </ConfirmProvider>
)

export default App
