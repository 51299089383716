import React, { useState } from "react"
import { Redirect } from 'react-router-dom'
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Button,
  Card,
  CardContent,
  Typography
} from '@material-ui/core'
import {
  useGetIdentity,
} from 'react-admin'
import SecurityIcon from '@material-ui/icons/Security'

const Me = (props) => {
  const { identity } = useGetIdentity()
  const [route, setRoute] = useState(null)

  return  identity
  ? (
    <Card>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" component="h6">Mis Datos</Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={() => { setRoute(`/me/change_password`) }}
              startIcon={<SecurityIcon />}
              color="primary"
            >
              Cambiar Password
            </Button>
          </div>
        </Grid>
        <TableContainer style={{ marginTop: '1em' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell><b>Nombre</b></TableCell>
                <TableCell>{identity.user.firstname}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Apellido</b></TableCell>
                <TableCell>{identity.user.lastname}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Nombre de Usuario</b></TableCell>
                <TableCell>{identity.user.username}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Rut</b></TableCell>
                <TableCell>{identity.user.identification}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Email</b></TableCell>
                <TableCell>{identity.user.email || '--'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      {
        route
        ? <Redirect to={route} />
        : null
      }
    </Card>
  )
  : <linearProgress />
}

export default Me;
