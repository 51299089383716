import React from "react"
import {
  TableContainer,
  Grid,
  IconButton
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import {
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  useShowController
} from 'react-admin'
import { BASE_URL } from '../../../providers/dataProvider'
import PermissionsEditableTable from './PermissionsEditableTable'

const PermissionsEdit = (props) => {
  const { record, loaded } = useShowController({
    ...props,
    basePath: BASE_URL,
    resource: 'auth/users',
    id: props.match.params.id
  })
  const { goBack } = useHistory()

  return  record && loaded
  ? (
    <Show
      title={`Permisos ${record.firstname} ${record.lastname}`}
      basePath={BASE_URL}
      resource="auth/users"
      id={props.match.params.id}
      {...props}
    >
      <SimpleShowLayout>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <IconButton
            onClick={() => goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <TableContainer>
          <ReferenceManyField 
            record={record}
            basePath={BASE_URL}
            source="id"
            reference="auth/users_permissions"
            sort={{ field: 'id', order: 'DESC' }}
            perPage={100}
            target="userid"
          >
            <PermissionsEditableTable />
          </ReferenceManyField>
        </TableContainer>
      </SimpleShowLayout>
    </Show>
  )
  : <linearProgress />
}

export default PermissionsEdit
