import React, { useState } from "react"
import { Redirect } from 'react-router-dom'
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Typography,
  IconButton,
  Button
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import {
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  useShowController,
  useNotify,
  usePermissions,
  useRedirect
} from 'react-admin'
import { BASE_URL, resetStaffPassword } from '../../../providers/dataProvider'
import PermissionsTable from './PermissionsTable'
import SecurityIcon from '@material-ui/icons/Security'
import EditIcon from '@material-ui/icons/Edit'
import { useConfirm } from 'material-ui-confirm'

const canWrite = (permissions) => {
  if (!permissions) {
    return false
  }

  if (!permissions['admin']) {
    return false
  }

  return permissions['admin/staff']['write']
}

const checkPermissions = (permissions, redirect) => {
  if (!permissions) {
    return
  }

  if ((permissions['admin'] || false) === false) {
    redirect('/forbidden')
  }
  if ((permissions['admin/staff'] || false) === false) {
    redirect('/forbidden')
  }
}

export default (props) => {
  const { permissions } = usePermissions()
  const redirect = useRedirect()

  checkPermissions(permissions, redirect)

  const { record, loaded } = useShowController({
    ...props,
    basePath: BASE_URL,
    resource: 'auth/users',
    id: props.match.params.id
  })
  const { goBack } = useHistory()
  const [route, setRoute] = useState(null)
  const notify = useNotify()
  const confirm = useConfirm()

  const resetPassword = () => {
    confirm({ description: 'Deseas resetear la password del usuario ?', title: 'Password' })
    .then(() => {
      notify('Restaurando Password...', 'info')
      resetStaffPassword(record.id)
      .then(() => {
        notify('Se ha enviado un correo al usuario con una nueva password', 'success', null, false, 3000)
      })
      .catch((err) => {
        notify('No logro resetearse la password del usuario', 'error', null, false, 3000)
      })
    })
    .catch((err) => {
      console.log(err)
    });
  }

  return  record && loaded
  ? (
    <Show
      title={`${record.firstname} ${record.lastname}`}
      basePath={BASE_URL}
      resource="auth/users"
      id={props.match.params.id}
      {...props}
    >
      <SimpleShowLayout>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => goBack()}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="h6">Datos usuario</Typography>
          </div>
          {
            canWrite(permissions) && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  onClick={() => { setRoute(`/admin/staff/${record.id}/edit`) }}
                  startIcon={<EditIcon />}
                  color="primary"
                >
                  Editar
                </Button>
                <Button
                  style={{ marginLeft: '1em' }}
                  onClick={() => resetPassword()}
                  startIcon={<SecurityIcon />}
                  color="primary"
                >
                  Resetear Password
                </Button>
              </div>
            )
          }
        </Grid>
        <TableContainer style={{ marginTop: '1em' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell><b>Nombre</b></TableCell>
                <TableCell>{record.firstname}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Apellido</b></TableCell>
                <TableCell>{record.lastname}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Nombre de Usuario</b></TableCell>
                <TableCell>{record.username}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Rut</b></TableCell>
                <TableCell>{record.identification}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Email</b></TableCell>
                <TableCell>{record.email || '--'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Administrador</b></TableCell>
                <TableCell>{record.isadmin ? 'SI' : 'NO'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Habilitado</b></TableCell>
                <TableCell>{record.active ? 'SI' : 'NO'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Fecha/Hora Creacion</b></TableCell>
                <TableCell>{new Date(record.createdat).toLocaleDateString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Fecha/Hora Ultima Modificacion</b></TableCell>
                <TableCell>{record.updatedat ? new Date(record.updatedat).toLocaleDateString() : '--'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Grid
          style={{ marginBottom: '1em', marginTop: '2em' }}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" component="h6">Permisos</Typography>
          {
            (record.isadmin === false && canWrite(permissions)) && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  onClick={() => { setRoute(`/admin/staff/${record.id}/permissions/edit`) }}
                  startIcon={<EditIcon />}
                  color="primary"
                >
                  Editar
                </Button>
              </div>
            )
          }
        </Grid>

        <TableContainer>
          <ReferenceManyField 
            record={record}
            basePath={BASE_URL}
            source="id"
            reference="auth/users_permissions"
            sort={{ field: 'id', order: 'DESC' }}
            perPage={100}
            target="userid"
          >
            <PermissionsTable isAdmin={record.isadmin} />
          </ReferenceManyField>
        </TableContainer>
        {
          route
          ? <Redirect to={route} />
          : null
        }
      </SimpleShowLayout>
    </Show>
  )
  : <linearProgress />
}
