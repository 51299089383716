import React from 'react'
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'

const ValueToText = ({ value = null }) => {
  if (!value) {
    return (<span>--</span>)
  }

  if ((typeof value) === 'boolean') {
    return (<span>{value ? 'true' : 'false'}</span>)
  }

  if ((value instanceof Array)) {
    if (value.length === 0) {
      return (<span>--</span>)
    }
  }

  if ((typeof value) === 'object') {
    if (Object.keys(value) === 0) {
      return (<span>--</span>)
    }

    return (<JsonTable json={value} />)
  }

  return (
    <span>{value}</span>
  )
}

const JsonTable = ({ json, mapLabels, skip, upper = false }) => {
  const valueTable = (
    <TableContainer>
      <Table style={{ backgroundColor: '#FAFAFA' }}>
        <TableBody>
          <TableRow>
            <TableCell>
              <ValueToText value={json} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )

  if (!json) {
    return valueTable
  }

  if (isNaN(Number(json)) === false) {
    return valueTable
  }

  if ((typeof json) === 'string') {
    try {
      json = JSON.parse(json)
    }
    catch(err) {
      return valueTable
    }
  }

  if ((typeof json) === 'string') {
    return valueTable
  }

  return (
    <TableContainer>
      <Table style={{ backgroundColor: '#FAFAFA' }}>
        <TableBody>
          {
            Object.keys(json).filter(header => skip ? !skip.includes(header) : true).map(header => (
              <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>{mapLabels ? (mapLabels[header] || (upper ? header.toUpperCase() : header)) : (upper ? header.toUpperCase() : header)}</TableCell>
                  <TableCell>
                    <ValueToText value={json[header]} />
                  </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default JsonTable
