import { fetchUtils } from 'react-admin'
import simpleRestProvider from 'ra-data-simple-rest'
import axios from 'axios'

 export const BASE_URL = 'https://zicsa.hitch.cl/dashboard/api'
 export const MODULES_API_URL = 'https://zicsa.hitch.cl/modules/api'

export const defaultClient = () => {
    const token = localStorage.getItem('token')
    
    return axios.create({
        baseURL: BASE_URL,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export const modulesApiClient = () => {
    const token = localStorage.getItem('token')
    
    return axios.create({
        baseURL: MODULES_API_URL,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export const defaultConfig = (path) => {
    const token = localStorage.getItem('token')
        
    return {
        method: 'get',
        url: `${BASE_URL}${path}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
}

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
  
    const token = localStorage.getItem('token')
    options.headers.set('Authorization', `Bearer ${token}`)
  
    return fetchUtils.fetchJson(url, options)
}

export const retryIntegration = (module, resource, id) => {
    return new Promise((resolve, _) => {
        modulesApiClient()
        .post(`/${module}/${resource}/retry/${id}`)
        .then(() => resolve(true))
        .catch(() => resolve(false))
    })
}

export const closeIntegration = (module, resource, id) => {
    return new Promise((resolve, _) => {
        modulesApiClient()
        .post(`/${module}/${resource}/close/${id}`)
        .then(() => resolve(true))
        .catch(() => resolve(false))
    })
}

export const resetStaffPassword = (staffId) => {
    return new Promise((resolve, reject) => {
        defaultClient()
        .post('/auth/reset_password', { staffId })
        .then(() => {
            resolve()
        })
        .catch(err => reject(err))
    })
}

export const changePassword = (staffId, password) => {
    return new Promise((resolve, reject) => {
        defaultClient()
        .post('/auth/change_password', { staffId , password})
        .then(() => {
            resolve()
        })
        .catch(err => reject(err))
    })
}

export const deleteStaff = (staffId) => {
    return new Promise((resolve, reject) => {
        defaultClient()
        .delete(`/auth/staff/${staffId}`)
        .then(() => {
            resolve()
        })
        .catch(err => reject(err))
    })
}

export const integrakResume = () => {
    return new Promise((resolve, _) => {
        defaultClient()
        .get('/main/resume')
        .then(({ data }) => resolve(data))
        .catch(() => resolve([]))
    })
}


export default simpleRestProvider(BASE_URL, fetchJson)
