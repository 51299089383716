import React, { useState, useEffect } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  Checkbox
} from '@material-ui/core'
import { useMutation } from 'react-admin'

const appLabel = (app) => {
  if (app === 'zicsa') {
    return 'Zicsa'
  }
  else if (app === 'admin') {
    return 'Administracion'
  }
}

const menuLabel = (menu) => {
  if (menu === 'staff') {
    return 'Usuarios'
  }
  else if (menu === 'purchase_orders') {
    return 'Ordenes de compra'
  }
  else if (menu === 'invoices') {
    return 'Facturas'
  }
  else if (menu === 'status_invoices') {
    return 'Estado facturas'
  }
  else if (menu === 'invoices_journal') {
    return 'Registros contables'
  }
}

const PermissionEditableRow = ({ record }) => {
  const [read, setRead] = useState(record.read)
  const [write, setWrite] = useState(record.write)
  const [changeReadPermission, payloadRead] = useMutation()
  const [changeWritePermission, payloadWrite] = useMutation()

  const onChangeRead = (event) => {
    setRead(event.target.checked)
    changeReadPermission({
      type: 'update',
      resource: 'auth/users_permissions',
      payload: {
        id: record.id,
        data: { read: event.target.checked, updatedat: new Date() }
      }
    })
  }

  const onChangeWrite = (event) => {
    setWrite(event.target.checked)
    changeWritePermission({
      type: 'update',
      resource: 'auth/users_permissions',
      payload: {
        id: record.id,
        data: { write: event.target.checked, updatedat: new Date() }
      }
    })
  }

  return (
    <TableRow>
      <TableCell
        align="left"
      >
        {appLabel(record.app)}
      </TableCell>
      <TableCell
        align="left"
      >
        {menuLabel(record.menu)}
      </TableCell>
      <TableCell
        align="left"
      >
        <Checkbox
          checked={read}
          disabled={payloadRead.loading}
          onChange={(e) => onChangeRead(e)}
        />
      </TableCell>
      <TableCell
        align="left"
      >
        <Checkbox
          checked={write}
          disabled={payloadWrite.loading}
          onChange={(e) => onChangeWrite(e)}
        />
      </TableCell>
    </TableRow>
  )
}

const PermissionsEditableTable = ({ data, loaded }) => {
  const [permissions, setPermissions] = useState(null)

  useEffect(() => {
    if (data) {
      const items = Object.values(data)
        .reduce((accu, permission) => {
          accu[`${permission.app}-${permission.menu}`] = permission
          return accu
        }, {})
      setPermissions(items)
    }
  }, [data])

  if ((loaded && data) === false) {
    return <LinearProgress />
  }

  if (!permissions) {
    return <LinearProgress />
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            align="left"
          >
            Aplicación
          </TableCell>
          <TableCell
            align="left"
          >
            Menu
          </TableCell>
          <TableCell
            align="left"
          >
            Lectura
          </TableCell>
          <TableCell
            align="left"
          >
            Escritura
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          Object.keys(permissions || {}).length > 0 && (
            <React.Fragment>
              <PermissionEditableRow record={permissions['zicsa-purchase_orders']} />
              <PermissionEditableRow record={permissions['zicsa-invoices']} />
              <PermissionEditableRow record={permissions['zicsa-status_invoices']} />
              <PermissionEditableRow record={permissions['zicsa-invoices_journal']} />
              
              <PermissionEditableRow record={permissions['admin-staff']} />
            </React.Fragment>
          )
        }
    </TableBody>
    </Table>
  )
}

export default PermissionsEditableTable