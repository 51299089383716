import polyglotI18nProvider from 'ra-i18n-polyglot'
import spanishMessages from 'ra-language-english'

const messages = {
    en: spanishMessages
}

export default polyglotI18nProvider(locale => messages[locale], 'en', {
  allowMissing: true,
  onMissingKey: (key, _, __) => key
})