import React from 'react'
import {
  TextInput,
  useRedirect,
  useRefresh,
  useNotify,
  SelectInput,
  SimpleForm,
  Create
} from 'react-admin'
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid
} from '@material-ui/core'
import moment from 'moment'
import { BASE_URL } from '../../../providers/dataProvider'

const activeChoices = [
  { id: 'true', name: 'Habilitado' },
  { id: 'false', name: 'No Habilitado' }
]
const isAdminChoices = [
  { id: 'true', name: 'SI' },
  { id: 'false', name: 'NO' }
]

const staffDefaultValues = () => ({
  "active": true,
  "isadmin": false
})

export default (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('Usuario creado', 'success', null, false, 3000)
    redirect(`/admin/staff`)
    refresh()
  }

  const onFailure = (error) => {
    notify(`No logro crearse el usuario: ${error.message}`, 'error', null, false, 3000)
  }

  const transform = (staff) => {
    delete staff['id']
    delete staff['passwordhash']
    delete staff['createdat']
    staff['updatedat'] = moment().format('YYYY-MM-DD[T]HH:mm:ss')
    return staff
  }

  const validateStaff = (values) => {
    const errors = {}

    if ((values.firstname || '').length === 0) {
      errors.firstname = 'Debes ingresar el primer nombre del usuario'
    }
    if ((values.lastname || '').length === 0) {
      errors.lastname = 'Debes ingresar el apellido del usuario'
    }
    if ((values.username || '').length === 0) {
      errors.username = 'Debes ingresar el nombre de usuario'
    }
    if ((values.identification || '').length === 0) {
      errors.identification = 'Debes ingresar el rut de la cuenta'
    }
    else {
      if (/^\d{7,8}-[0-9kK]{1}$/.test(values.identification) === false) {
        errors.identification  = 'El formato del rut no es valido (XXXXXXXX-X)'
      }
    }
    if ((values.email || '').length === 0) {
      errors.email = 'Debes ingresar el correo del usuario'
    }
    else {
      if (/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(values.email) === false) {
        errors.email = 'El formato del correo el usuario no es valido'
      }
    }

    return errors
  }

  return (
    <Create
      title={`Nuevo Usuario Middleware`}
      onSuccess={onSuccess}
      onFailure={onFailure}
      transform={transform}
      basePath={BASE_URL}
      resource="auth/users"
      undoable={false}
      {...props}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        validate={validateStaff}
        initialValues={staffDefaultValues}
      >
        <Grid container style={{ width: '100%' }}>
          <Grid item xs={12}>
            <TableContainer style={{ marginTop: '1em' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell><b>Nombre</b></TableCell>
                    <TableCell>
                      <TextInput source="firstname" label="" fullWidth />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Apellido</b></TableCell>
                    <TableCell>
                      <TextInput source="lastname" label="" fullWidth />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Nombre de Usuario</b></TableCell>
                    <TableCell>
                      <TextInput source="username" label="" fullWidth />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Rut</b></TableCell>
                    <TableCell>
                      <TextInput source="identification" label="" fullWidth />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Email</b></TableCell>
                    <TableCell>
                      <TextInput source="email" label="" fullWidth />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Estado</b></TableCell>
                    <TableCell>
                      <SelectInput source="active" label="" choices={activeChoices} fullWidth />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Administrador</b></TableCell>
                    <TableCell>
                      <SelectInput source="isadmin" label="" choices={isAdminChoices} fullWidth />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}
