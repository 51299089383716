import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { MenuItemLink, usePermissions } from 'react-admin'
import { withRouter } from 'react-router-dom'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import AppsIcon from '@material-ui/icons/Apps'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { List, ListItem, ListItemText, Collapse, CircularProgress } from '@material-ui/core'

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const [zicsaOpen, setZicsaOpen] = useState(true)
  const [adminOpen, setAdminOpen] = useState(true)
  const { permissions } = usePermissions()

  if (!permissions) {
    return (
      <div style={{ marginTop: '1em' }}>
        <List component="nav">
          <ListItem>
            <CircularProgress />
          </ListItem>
        </List>
      </div>
    )
  }

  return (
    <div style={{ marginTop: '1em' }}>
      <List component="nav" style={{ backgroundColor: 'white' }}>
        <ListItem>
          <MenuItemLink
            to="/"
            primaryText={<span style={{ fontSize: '16px' }}>Inicio</span>}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </ListItem>
        {
          (permissions['zicsa'] || false) && (
            <React.Fragment>
              <ListItem button onClick={() => setZicsaOpen(!zicsaOpen)} selected={zicsaOpen}>
                <ListItemText inset primary="Docuclass" />
                {zicsaOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={zicsaOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {
                    (permissions['zicsa/purchase_orders'] || false) && (
                      <MenuItemLink
                        to="/zicsa/purchase_orders"
                        primaryText={<span style={{ fontSize: '16px' }}>Ordenes de compra</span>}
                        leftIcon={<BookmarkIcon fontSize="medium" titleAccess="Queseyo" />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                      />
                    )
                  }
                  {
                    (permissions['zicsa/invoices'] || false) && (
                      <MenuItemLink
                        to="/zicsa/invoices"
                        primaryText={<span style={{ fontSize: '16px' }}>Facturas</span>}
                        leftIcon={<BookmarkIcon fontSize="medium" titleAccess="Queseyo" />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                      />
                    )
                  }
                  {
                    (permissions['zicsa/status_invoices'] || false) && (
                      <MenuItemLink
                        to="/zicsa/status_invoices"
                        primaryText={<span style={{ fontSize: '16px' }}>Estados Facturas</span>}
                        leftIcon={<BookmarkIcon fontSize="medium" titleAccess="Queseyo" />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                      />
                    )
                  }
                  {
                    (permissions['zicsa/invoices_journal'] || false) && (
                      <MenuItemLink
                        to="/zicsa/invoices_journal"
                        primaryText={<span style={{ fontSize: '16px' }}>Registro Contable</span>}
                        leftIcon={<BookmarkIcon fontSize="medium" titleAccess="Queseyo" />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                      />
                    )
                  }
                </List>
              </Collapse>
            </React.Fragment>
          )
        }
        {
          (permissions['admin'] || false) && (
            <React.Fragment>
              <ListItem button onClick={() => setAdminOpen(!adminOpen)} selected={adminOpen}>
                <ListItemText inset primary="Administracion" />
                {adminOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={adminOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {
                    (permissions['admin/staff'] || false) && (
                      <MenuItemLink
                        to="/admin/staff"
                        primaryText={<span style={{ fontSize: '16px' }}>Usuarios</span>}
                        leftIcon={<AppsIcon fontSize="medium" titleAccess="Apps icon"/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                      />
                    )
                  }
                </List>
              </Collapse>
            </React.Fragment>
          )
        }
      </List>
      {isXSmall && logout}
    </div>
  )
}

export default withRouter(Menu)
