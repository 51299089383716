import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import {
  useShowController,
  useNotify,
  useRefresh,
  Show
} from 'react-admin'
import {
  makeStyles,
  Divider,
  Card,
  Grid,
  Container,
  Typography,
  IconButton,
  LinearProgress
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import JsonTable from '../../JsonTable'
import { BASE_URL, retryIntegration, closeIntegration } from '../../../providers/dataProvider'
import { Alert } from '@material-ui/lab'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { StatusField } from '../StatusField'
import ReactJson from 'react-json-view'
import JsonView from '../JsonView'

const useStyles = makeStyles((theme) => ({
  padding2: {
    padding: theme.spacing(2)
  },
  mt2: {
    marginTop: '2em'
  },
  mb2: {
    marginBottom: '2em'
  }
}))

const CreatedAtField = ({ record = {} }) => (
  <Typography variant="body1" component="p">
    <b>Fecha inicio integración:</b> <span>{new Date(record.createdat).toLocaleDateString()}</span>
  </Typography>
)

const FinishAtField = ({ record = {} }) => (
  <Typography variant="body1" component="p">
    <b>Fecha integración completada:</b> <span>{record.finishat ? new Date(record.finishat).toLocaleDateString() : '--'}</span>
  </Typography>
)

const WrapperOrderReference = ({ record = {} }) => (
  <React.Fragment>
    <WrapperReference record={record} />
  </React.Fragment>
)

const WrapperReference = ({ record = {} }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Typography
        style={{ marginBottom: '1em' }}
        variant="h6"
        component="h6"
      >
        Datos factura Docuclass
      </Typography>
      <JsonTable
        json={record}
        upper={true}
        mapLabels={{ nro_factura: 'Numero Factura' }}
      />
      <Divider
        className={[classes.mb2, classes.mt2]}
      />
    </React.Fragment>
  )
}

const DefaultError = ({ record = {} }) => (
  record && record.status === 'Failed'
    ? <React.Fragment>
      <Alert style={{ marginTop: '1em' }} severity="error">
        <AlertTitle>Error</AlertTitle>
        {
          record.errortype && (
            <AlertTitle>Ocurrio en: {record.errortype}</AlertTitle>
          )
        }
        {record.error}
      </Alert>
      <Divider
        style={{ marginTop: '2em', marginBottom: '2em' }}
      />
    </React.Fragment>
    : null
)

const ActionsButton = ({ record = {} }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [open, setOpen] = useState(false)

  if (record.status != 'Failed') {
    return null
  }

  const retry = () => {
    retryIntegration('zicsa', 'invoiceDataForJournalEntry', record.resourceitemid)
      .then((success) => {
        if (success) {
          notify('La factura se ha vuelto a procesar. Espera unos segundos a que termine y recarga para ver los resultados.', 'info', null, false, 3000)
        }
        else {
          notify('No ha sido posible volver a procesar la factura. Intentalo nuevamente.', 'warning', null, false, 3000)
        }
      })
  }

  const close = () => {
    setOpen(false)
    closeIntegration('zicsa', 'invoiceDataForJournalEntry', record.resourceitemid)
      .then((success) => {
        if (success) {
          notify('La factura ha sido cerrada.', 'info', null, false, 3000)
          refresh()
        }
        else {
          notify('No ha sido posible cerrar la factura. Intentalo nuevamente.', 'warning', null, false, 3000)
        }
      })
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <ButtonGroup color="primary" size="small">
        {
          (record.status == 'Failed') && (
            <Button onClick={() => setOpen(true)}>Cerrar</Button>
          )
        }
        {
          (record.status == 'Failed') && (
            <Button onClick={() => retry()}>Reproceso</Button>
          )
        }
      </ButtonGroup>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Cerrar Factura</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseas cerrar esta factura ? No podras revertir el cambio.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            NO
          </Button>
          <Button onClick={() => close()} color="primary">
            SI
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default (props) => {
  const classes = useStyles()
  const { loaded, record } = useShowController({
    ...props,
    basePath: BASE_URL,
    resource: 'zicsa/integration_result',
    id: props.match.params.id
  })
  const { goBack } = useHistory()

  return record && loaded
    ? (
      <Show
        title={`Registro contable factura, ${record.resourceitemid}`}
        component="div"
        basePath={BASE_URL}
        resource="zicsa/integration_result"
        id={props.match.params.id}
        {...props}
      >
        <Container>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid
              item
              sm={9}
              xs={12}
            >
              <Card
                className={classes.padding2}
              >
                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                >
                  <div>
                    <IconButton
                      onClick={() => goBack()}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </div>
                  <div>
                    <StatusField record={record} />
                    <CreatedAtField record={record} />
                    <FinishAtField record={record} />
                    <ActionsButton record={record} />
                  </div>
                </Grid>
                <Divider
                  className={[classes.mb2, classes.mt2]}
                />

                <DefaultError record={record} />

                <WrapperOrderReference record={{
                  nro_factura: record.resourceitemid
                }} />

                <Typography
                  style={{ marginBottom: '1em' }}
                  variant="h6"
                  component="h6"
                >
                  Respuesta de SAP
                </Typography>

                <JsonView value={record.response} />

                <Divider
                  className={[classes.mb2, classes.mt2]}
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Show>
    )
    : (
      <LinearProgress />
    )
}
