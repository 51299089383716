import React, { useState } from "react"
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import {
  List,
  Datagrid,
  DateField,
  Pagination,
  useNotify,
  useRefresh,
  TextField
} from 'react-admin'
import { BASE_URL, retryIntegration, closeIntegration } from '../../../providers/dataProvider'
import { StatusFieldCell } from '../StatusField'
import DefaultFiltersAndActions from "../DefaultFiltersAndActions"
import DetailButton from '../DetailButton'


const DefaultPagination = (props) => {
  // !FIX: Cuando se hace esto, si propiedad total es undefined entonces count no existe, tomandolo como undefined y dando errores como este en consola:
  // Prop `count` required, but its value is `undefined`
  return (<Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />)
}

const ActionsButton = ({ record = {} }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [open, setOpen] = useState(false)
  
  const retry = () => {
    retryIntegration('zicsa', 'invoice', record.resourceitemid)
    .then((success) => {
      if (success) {
        notify('La factura se ha vuelto a procesar. Espera unos segundos a que termine y recarga para ver los resultados.', 'info', null, false, 3000)
      }
      else {
        notify('No ha sido posible volver a procesar la factura. Intentalo nuevamente.', 'warning', null, false, 3000)
      }
    })
  }

  const close = () => {
    setOpen(false)
    closeIntegration('zicsa', 'invoice', record.resourceitemid)
    .then((success) => {
      if (success) {
        notify('La factura ha sido cerrada.', 'info', null, false, 3000)
        refresh()
      }
      else {
        notify('No ha sido posible cerrar la factura. Intentalo nuevamente.', 'warning', null, false, 3000)
      }
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <ButtonGroup color="primary" size="small">
        {
          (record.status == 'Failed') && (
            <Button onClick={() => setOpen(true)}>Cerrar</Button>
          )
        }
        {
          (record.status == 'Failed') && (
            <Button onClick={() => retry()}>Reproceso</Button>
          )
        }
      </ButtonGroup>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Cerrar Factura</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseas cerrar esta factura ? No podras revertir el cambio.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            NO
          </Button>
          <Button onClick={() => close()} color="primary">
            SI
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  ) 
}

const IntegrationResultList = (props) => {
  const { staticContext, ...restProps } = props;

  return (
    <List
      perPage={25}
      title="Facturas"
      bulkActionButtons={false}
      pagination={<DefaultPagination />}
      exporter={false}
      basePath={BASE_URL}
      resource="zicsa/integration_result"
      filter={{ resourceid: "f37b934d-0b58-4d32-821a-c19d9f47a110" }}
      sort={{ field: 'updatedat', order: 'DESC' }}
      actions={<DefaultFiltersAndActions resourceName="invoices" />}
      {...restProps}
    >
      <Datagrid
        stickyHeader
      >
        <TextField source="resourceitemid" label="Numero Factura" emptyText="-" sortable />
        <TextField source="referencefield1" label="RUC Proveedor" emptyText="-" sortable />
        <TextField source="referencefield2" label="RUC Cliente" emptyText="-" sortable />
        <TextField source="referencefield3" label="Numero OC" emptyText="-" sortable />
        <StatusFieldCell label="Estado" sortable />
        <DateField source="createdat" label="Fecha Inicio" showTime locales="es-CL" sortable />
        <DateField source="updatedat" label="Ultima Actualizacion" showTime locales="es-CL" sortable emptyText="-" />
        <DetailButton resourceName="invoices" />
        <ActionsButton />
      </Datagrid>
    </List>
  )
}

export default IntegrationResultList
