import React from "react"
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  LinearProgress,
  Button
} from '@material-ui/core'
import {
  Edit,
  SimpleForm,
  useShowController,
  TextInput,
  SelectInput,
  Toolbar,
  SaveButton,
  useNotify,
  useRefresh,
  useRedirect,
  useGetIdentity
} from 'react-admin'
import { BASE_URL, deleteStaff } from "../../../providers/dataProvider"
import moment from 'moment'
import DeleteIcon from '@material-ui/icons/Delete'
import { useConfirm } from 'material-ui-confirm'

const activeChoices = [
  { id: 'true', name: 'Habilitado' },
  { id: 'false', name: 'No Habilitado' }
]
const isAdminChoices = [
  { id: 'true', name: 'SI' },
  { id: 'false', name: 'NO' }
]

export default (props) => {
  const { record, loaded } = useShowController({
    ...props,
    basePath: BASE_URL,
    resource: "auth/users",
    id: props.match.params.id
  })

  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const { identity } = useGetIdentity()
  const confirm = useConfirm()

  const onSuccess = () => {
    notify('Usuario modificado', 'success', null, false, 3000)
    redirect(`/admin/staff/${record.id}/show`)
    refresh()
  }

  const onFailure = (error) => {
    notify(`No logro modificarse el usuario: ${error.message}`, 'error', null, false, 3000)
  }

  const removeStaff = () => {
    confirm({ description: 'Deseas eliminar el usuario ?', title: 'Eliminar usuario' })
    .then(() => {
      notify('Eliminando usuario...', 'info', null, false, 3000)
      deleteStaff(record.id)
      .then(() => {
        notify('Usuario eliminado', 'success', null, false, 3000)
        redirect('/admin/staff')
        refresh()
      })
      .catch(err => {
        notify(`No logro eliminarse el usuario. ${err.message}`, 'error', null, false, 3000)
      })
    })
  }

  const EditToolbar = (props) => {
    return (
      <Toolbar style={{ justifyContent: 'space-between' }} {...props}>
        <SaveButton />
        {
          (identity && record.id !== identity.user.id) && (
            <Button
              onClick={() => removeStaff()}
              startIcon={<DeleteIcon />}
              color="danger"
              variant="contained"
            >
              DELETE
            </Button>
          )
        }
      </Toolbar> 
    )
  }

  const validateStaff = (values) => {
    const errors = {}

    if ((values.firstname || '').length === 0) {
      errors.firstname = 'Debes ingresar el primer nombre del usuario'
    }
    if ((values.lastname || '').length === 0) {
      errors.lastname = 'Debes ingresar el apellido del usuario'
    }
    if ((values.username || '').length === 0) {
      errors.username = 'Debes ingresar el nombre de usuario'
    }
    if ((values.identification || '').length === 0) {
      errors.identification = 'Debes ingresar el rut de la cuenta'
    }
    else {
      if (/^\d{7,8}-[0-9kK]{1}$/.test(values.identification) === false) {
        errors.identification  = 'El formato del rut no es valido (XXXXXXXX-X)'
      }
    }
    if ((values.email || '').length === 0) {
      errors.email = 'Debes ingresar el correo del usuario'
    }
    else {
      if (/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(values.email) === false) {
        errors.email = 'El formato del correo el usuario no es valido'
      }
    }

    return errors
  }

  const transform = (staff) => {
    delete staff['passwordhash']
    delete staff['createdat']
    staff['updatedat'] = moment().format('YYYY-MM-DD[T]HH:mm:ss')
    return staff
  }

  return  record && loaded
  ? (
    <Edit
      title={`${record.firstname} ${record.lastname}`}
      hasEdit={true}
      basePath={BASE_URL}
      resource="auth/users"
      id={record.id}
      transform={transform}
      onSuccess={onSuccess}
      onFailure={onFailure}
      undoable={false}
      {...props}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        validate={validateStaff}
        toolbar={<EditToolbar />}
      >
        <Grid container style={{ width: '100%' }}>
          <Grid item xs={12}>
            <TableContainer style={{ marginTop: '1em' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell><b>Nombre</b></TableCell>
                    <TableCell>
                      <TextInput record={record} source="firstname" label="" fullWidth />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Apellido</b></TableCell>
                    <TableCell>
                      <TextInput record={record} source="lastname" label="" fullWidth />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Nombre de Usuario</b></TableCell>
                    <TableCell>
                      <TextInput record={record} source="username" label="" fullWidth />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Rut</b></TableCell>
                    <TableCell>
                      <TextInput record={record} source="identification" label="" fullWidth />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Email</b></TableCell>
                    <TableCell>
                      <TextInput record={record} source="email" label="" fullWidth />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Estado</b></TableCell>
                    <TableCell>
                      <SelectInput record={record} source="active" label="" choices={activeChoices} fullWidth />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><b>Administrador</b></TableCell>
                    <TableCell>
                      <SelectInput record={record} source="isadmin" label="" choices={isAdminChoices} fullWidth />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </SimpleForm>      
    </Edit>
  )
  : <LinearProgress />
}
