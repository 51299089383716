import React from 'react'
import { Layout, AppBar } from 'react-admin'
import CustomUserMenu from './UserMenu'
import Menu from './Menu'

const CustomAppBar = (props) => (<AppBar {...props} userMenu={<CustomUserMenu />} />)

const AppLayout = (props) => (<Layout
    {...props}
    menu={Menu}
    appBar={CustomAppBar}
/>)

export default AppLayout
