import React, { useEffect, useState } from "react"
import Box from '@material-ui/core/Box'
import {
  TextInput,
  SelectInput,
  useListContext
} from 'react-admin'
import DateRangeInput from "./DateRangeInput"
import { Form, FormSpy } from 'react-final-form'

const FormSpySubscription = { values: true, pristine: true, invalid: true }
const handleFinalFormSubmit = () => { }

export const statusChoices = [
  { id: '', name: 'Todos' },
  { id: 'InProgress', name: 'En Progreso' },
  { id: 'InReview', name: 'En Revision' },
  { id: 'Pendient', name: 'Pendientes' },
  { id: 'Finished', name: 'Integrados' },
  { id: 'Failed', name: 'Fallidos' },
  { id: 'Closed', name: 'Cerrados' },
  { id: 'InReview', name: 'En Revision' }
]

const DefaultFilters = ({ resourceName }) => {
  const [initialValues, setInitialValues] = useState(null)
  const [pageInit, setPageInit] = useState(false)

  const {
    setFilters,
    displayedFilters,
    setPage,
    page
  } = useListContext()

  useEffect(() => {
    if (resourceName) {
      const data = localStorage.getItem(`filters/${resourceName}`)

      if (!data) {
        setInitialValues({})
      }
      else {
        const filters = JSON.parse(data)
        setInitialValues(filters)
        setFilters(filters, displayedFilters)
      }
    }
    else {
      setInitialValues({})
    }
  }, [resourceName])

  useEffect(() => {
    if (resourceName && pageInit === false) {
      const latestPage = localStorage.getItem(`pagination/${resourceName}`)

      if (latestPage) {
        localStorage.removeItem(`pagination/${resourceName}`)

        if (Number(latestPage) !== page) {
          setPageInit(true)
          setTimeout(() => setPage(Number(latestPage)), 1000)
        }
      }
      else {
        setPageInit(true)
      }
    }

  }, [resourceName, page])

  if (!initialValues) {
    return null
  }

  return (
    <Form
      onSubmit={handleFinalFormSubmit}
      initialValues={initialValues}
      render={(formProps) => {
        return (
          <>
            <FormSpy
              subscription={FormSpySubscription}
              onChange={({ pristine, values, invalid }) => {
                if (pristine || invalid) {
                  return
                }
                setFilters(values, displayedFilters)

                if (resourceName) {
                  localStorage.setItem(`filters/${resourceName}`, JSON.stringify(values))
                }
              }}
            />
            <form onSubmit={formProps.handleSubmit}>
              <Box display="flex" alignItems="center">
                <Box component="span" mr={2}>
                  <TextInput
                    source="q"
                    label="Search"
                    helperText={false}
                  />
                </Box>
                <Box component="span" mr={2}>
                  <SelectInput
                    source="status"
                    label="Estado"
                    choices={statusChoices}
                    helperText={false}
                  />
                </Box>
                <Box component="span" mr={2}>
                  <DateRangeInput
                    onChange={(range) => formProps.form.change('updatedat', range)}
                  />
                </Box>
              </Box>
            </form>
          </>
        )
      }}
    />
  )
}

const DefaultFiltersAndActions = ({ resourceName,  }) => {
  return (
    <Box width="100%">
      <DefaultFilters resourceName={resourceName} />
    </Box>
  )
}

export default DefaultFiltersAndActions