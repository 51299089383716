import React, { useState } from "react"
import InputAdornment from '@material-ui/core/InputAdornment'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ClearIcon from '@material-ui/icons/Clear'
import {
    TextInput
} from 'react-admin'
import { DateRangePicker } from 'materialui-daterange-picker'
import moment from 'moment'
import {
  addDays,
  startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
} from 'date-fns'

const getDefaultRanges = (date) => [
  {
    label: 'Hoy',
    startDate: date,
    endDate: date,
  },
  {
    label: 'Ayer',
    startDate: addDays(date, -1),
    endDate: addDays(date, -1),
  },
  {
    label: 'Esta Semana',
    startDate: startOfWeek(date),
    endDate: endOfWeek(date),
  },
  {
    label: 'Ultima Semana',
    startDate: startOfWeek(addWeeks(date, -1)),
    endDate: endOfWeek(addWeeks(date, -1)),
  },
  {
    label: 'Ultimos 7 Dias',
    startDate: addWeeks(date, -1),
    endDate: date,
  },
  {
    label: 'Este Mes',
    startDate: startOfMonth(date),
    endDate: endOfMonth(date),
  },
  {
    label: 'Ultimo Mes',
    startDate: startOfMonth(addMonths(date, -1)),
    endDate: endOfMonth(addMonths(date, -1)),
  },
]

const DateRangeInput = ({ onChange, name = "updatedat", label = "Ultima actualizacion" }) => {
    const [open, setOpen] = useState(false)
  
    const onRangeChange = (range) => {
      const startDate = moment(range.startDate).format('YYYY-MM-DD')
      const endDate = moment(range.endDate).format('YYYY-MM-DD')
      setOpen(false)
      onChange(JSON.stringify([startDate, endDate]))
    }
  
    const formatRangeValue = (v) => {
      if (!v) {
        return ''
      }
      const range = JSON.parse(v)
      const startDate = moment(range[0]).format('DD/MM/YYYY')
      const endDate = moment(range[1]).format('DD/MM/YYYY')
      return `${startDate} - ${endDate}`
    }
  
    const resetValues = () => {
      setOpen(false)
      onChange(null)
    }
  
    return (
      <>
        <TextInput
          source={name}
          label={label}
          helperText={false}
          format={formatRangeValue}
          disabled
          InputProps={{
            endAdornment: (
                <InputAdornment style={{ cursor: "pointer", marginTop: 0 }} position="end">
                    <DateRangeIcon color="primary" onClick={() => setOpen(true)} />
                    <ClearIcon color="action" onClick={() => resetValues()} />
                </InputAdornment>
            )
          }}
        />
        <DateRangePicker
          style={{ zIndex: 100000, position: 'absolute' }}
          open={open}
          toggle={() => setOpen(!open)}
          onChange={onRangeChange}
          definedRanges={getDefaultRanges(new Date())}
          wrapperClassName="custom-date-range"
        />
      </>
    )
}

export default DateRangeInput