import React from 'react'
import ReactJson from 'react-json-view'
import JsonTable from '../JsonTable'

export default ({ value = {} }) => {
    let json = null
  
    if (!value) {
      return (
        <JsonTable json={""} />
      )
    }
  
    try {
      json = JSON.parse(value)
    }
    catch (err) {
      return (
        <JsonTable json={value} />
      )
    }
  
    if ((typeof json) === 'string') {
      return (
        <JsonTable json={json} />
      )
    }
  
  
    return (
      <ReactJson
        name={false}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={true}
        collapseStringsAfterLength={false}
        collapsed={false}
        src={json}
      />
    )
  }