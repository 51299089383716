import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Title } from 'react-admin'
import { Typography, Card, IconButton, Divider, CardActions, Grid, Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ForwardIcon from '@material-ui/icons/Forward'
import {
    Alert, AlertTitle
} from '@material-ui/lab'
import { integrakResume } from '../providers/dataProvider'

const useStyles = makeStyles((theme) => ({
    infoContainer: {
        margin: '30px',
        marginTop: '4.5rem',
    },
    card: {
        display: 'flex',
        flexFlow: 'column',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingTop: '24px',
        borderRadius: '8px',
        overflow: 'hidden',
        backgroundColor: 'white'
    },
    chips: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        paddingLeft: '24px',
        paddingRight: '24px',
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}))


const appLabel = (appName) => {
    switch (appName) {
        case 'zicsa': return 'Docuclass'
        default: return '--'
    }
}

const appLink = (appName) => {
    switch (appName) {
        case 'zicsa': return '/zicsa/purchase_orders'
        default: return '--'
    }
}

const statusColor = (status) => {
    switch (status) {
        case 'Closed': return 'grey'
        case 'Finished':
            return 'green'
        case 'Failed':
            return 'red'
        case 'Pendient':
        case 'InProgress':
            return 'yellow'
        case 'InReview':
            return 'orange'
        default: return 'grey'
    }
}

const statusLabel = (status) => {
    switch (status) {
        case 'Closed': return 'Cerrados'
        case 'InReview': return 'En Revision'
        case 'Finished':
            return 'Integrados'
        case 'Failed':
            return 'Fallidos'
        case 'Pendient':
            return 'Pendientes'
        case 'InProgress':
            return 'En Progreso'
        default: return '--'
    }
}

const AppCard = ({ label, resume, link }) => {
    const classes = useStyles()
    const [route, setRoute] = useState(null)

    return (
        <Card className={classes.card} elevation={6}>
            <Typography style={{ paddingLeft: '24px', paddingRight: '24px' }} variant="subtitle1" component="p">{label}</Typography>
            <Divider style={{ marginBottom: '1em' }} />
            <Grid container className={classes.chips}>
                {
                    resume.map((item, index) => (
                        <Chip
                            key={label + '_' + index}
                            label={item.status + ': ' + item.value}
                            className={classes.chip}
                            style={{ color: item.color === 'yellow' ? 'black' : 'white', backgroundColor: item.color }}
                            size="small"
                        />
                    ))
                }
            </Grid>
            <Divider style={{ marginTop: '1em' }} />

            {/* <CardActions style={{ alignSelf: 'flex-end' }}>
                <IconButton onClick={() => setRoute(link)}>
                    <ForwardIcon />
                </IconButton>
            </CardActions> */} 
            
            {
                route
                    ? <Redirect to={route} />
                    : null
            }
        </Card>
    )
}

const Dashboard = () => {
    const [appsResume, setAppsResume] = useState(null)
    const classes = useStyles()

    useEffect(() => {
        integrakResume()
            .then(data => setAppsResume(data))
    }, [])

    if (!appsResume) {
        return null
    }
    
    if ((appsResume || []).length === 0) {
        return (
            <React.Fragment>
                <Title title="Middleware" />
                <div className={classes.infoContainer}>
                    <Grid container spacing={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Grid container spacing={3} className="mb-3">
                                <Grid item xs={12} md={12}>
                                    <Alert severity="error" style={{ paddingTop: 24, paddingBottom: 24 }}>
                                        <AlertTitle>Error</AlertTitle>
                                        No fue posible cargar la informacion de las aplicaciones
                                    </Alert>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Title title="Middleware" />
            <div className={classes.infoContainer}>
                <Typography component="h6" variant="subtitle1">Aplicaciones conectadas</Typography>
                <Divider style={{ marginBottom: '1em', height: 0 }} />
                <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={3} className="mb-3">
                            {
                                appsResume.map((app, index) => (
                                    <Grid key={index} item xs={12} md={4}>
                                        <AppCard
                                            label={appLabel(app.name)}
                                            link={appLink(app.name)}
                                            key={app.name}
                                            resume={app.resume.map(item => ({
                                                value: item.count,
                                                color: statusColor(item.status),
                                                status: statusLabel(item.status)
                                            }))}
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default Dashboard