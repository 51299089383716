import React, { useState } from "react"
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import {
  List,
  Datagrid,
  DateField,
  Pagination,
  useRedirect,
  useNotify,
  useRefresh,
  usePermissions,
  TextField
} from 'react-admin'
import { BASE_URL, retryIntegration, closeIntegration } from '../../../providers/dataProvider'
import { StatusFieldCell } from '../StatusField'
import DefaultFiltersAndActions from "../DefaultFiltersAndActions"
import DetailButton from '../DetailButton'

const DefaultPagination = (props) => (<Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />)

const ActionsButton = ({ record = {} }) => {
  const { permissions } = usePermissions()
  const notify = useNotify()
  const refresh = useRefresh()
  const [open, setOpen] = useState(false)
  
  const retry = () => {
    retryIntegration('zicsa', 'purchaseOrder', record.resourceitemid)
    .then((success) => {
      if (success) {
        notify('La orden se ha vuelto a procesar. Espera unos segundos a que termine y recarga para ver los resultados.', 'info', null, false, 3000)
      }
      else {
        notify('No ha sido posible volver a procesar la orden. Intentalo nuevamente.', 'warning', null, false, 3000)
      }
    })
  }

  const close = () => {
    setOpen(false)
    closeIntegration('zicsa', 'purchaseOrder', record.resourceitemid)
    .then((success) => {
      if (success) {
        notify('La orden ha sido cerrada.', 'info', null, false, 3000)
        refresh()
      }
      else {
        notify('No ha sido posible cerrar la orden. Intentalo nuevamente.', 'warning', null, false, 3000)
      }
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <ButtonGroup color="primary" size="small">
        {
          (record.status == 'Failed') && (
            <Button onClick={() => setOpen(true)}>Cerrar</Button>
          )
        }
        {
          (record.status == 'Failed') && (
            <Button onClick={() => retry()}>Reproceso</Button>
          )
        }
      </ButtonGroup>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Cerrar Orden</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseas cerrar esta orden ? No podras revertir el cambio.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            NO
          </Button>
          <Button onClick={() => close()} color="primary">
            SI
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  ) 
}

export default (props) => {
  return (
    <List
      perPage={25}
      title="Ordenes de compra"
      bulkActionButtons={false}
      pagination={<DefaultPagination />}
      exporter={false}
      basePath={BASE_URL}
      resource="zicsa/integration_result"
      filter={{ resourceid: "7c7dfd4f-ee6c-48a3-89c4-d3c97f8c931c" }}
      sort={{ field: 'updatedat', order: 'DESC' }}
      actions={<DefaultFiltersAndActions resourceName="purchase_orders" />}
      {...props}
    >
      <Datagrid
        stickyHeader
      >
        <TextField source="resourceitemid" label="Numero OC" emptyText="-" sortable />
        <StatusFieldCell label="Estado" sortable />
        <DateField source="createdat" label="Fecha Inicio" showTime locales="es-CL" sortable />
        <DateField source="updatedat" label="Ultima Actualizacion" showTime locales="es-CL" sortable emptyText="-" />
        <DetailButton resourceName="purchase_orders" />
        <ActionsButton />
      </Datagrid>
    </List>
  )
}
